import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({data}) => {

  let pageData = data.allContentfulBook.edges;

    let pageContent = pageData.map((edge) => {
        return <a key={edge.node.id} style={{display:'block',margin:'20px 0'}} href={'/ebooks/'+edge.node.id}>{ edge.node.title }</a>
    })
    
  return (
    <Layout>
    <SEO title="Home" />
    <div>
      <h2>All Ebooks</h2> 
      <div>{pageContent}</div>
    </div>
  </Layout>
  )
}

export const assetQuery = graphql`
  {
    allContentfulBook {
      edges {
        node {
          title
          id
        }
      }
    }
  }`

export default IndexPage
